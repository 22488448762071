@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Acumin";
  src: url("/src/assets/fonts/Inter-Light.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Acumin-Bold";
  src: url("/src/assets/fonts/Inter-Bold.ttf") format("opentype");
  font-weight: 400;
  font-style: bold;
}

@font-face {
  font-family: "Eina-Bold";
  src: url("/src/assets/fonts/Inter-Bold.ttf") format("opentype");
  font-weight: 400;
  font-style: bold;
}

@font-face {
  font-family: "Eina";
  src: url("/src/assets/fonts/Inter-Light.ttf") format("opentype");
  font-weight: 400;
  font-style: bold;
}
